import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  // cilChartPie,
  cilCursor,
  cilDrop,
  cilFilter,
  cilLightbulb,
  cilPencil,
  cilPuzzle,
  cilStar,
} from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const defaultPage = 'site.workspaces'

const navigationMap = new Map([
  [
    'site.users',
    {
      name: 'Users',
      to: '/users',
      icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.organizations',
    {
      name: 'Organizations',
      to: '/organizations',
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    defaultPage,
    {
      name: 'Workspaces',
      to: '/workspaces',
      icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.coupons',
    {
      name: 'Coupon',
      to: '/coupons',
      icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  // [
  //   'site.campaigns',
  //   {
  //     name: 'Campaigns',
  //     to: '/campaigns',
  //     icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  //     component: CNavItem,
  //   },
  // ],
  [
    'site.settings',
    {
      name: 'Settings',
      to: '/settings',
      icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.themes',
    {
      name: 'Themes',
      to: '/themes',
      icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.importer',
    {
      name: 'Importer',
      to: '/importer',
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.linkqr',
    {
      name: 'Link & Qrcode',
      to: '/linkqr',
      icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.demo',
    {
      name: 'Demo Pages',
      to: '/demo',
      icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.deviceorder',
    {
      name: 'Device Order',
      to: '/device-order',
      icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.adverts',
    {
      name: 'Adverts',
      to: '/adverts',
      icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.roles-permissions',
    {
      name: 'Roles & Permissions',
      to: '/roles-permissions',
      icon: <CIcon icon={cilFilter} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
  [
    'site.orders',
    {
      name: 'Orders',
      to: '/orders',
      icon: <CIcon icon={cilLightbulb} customClassName="nav-icon" />,
      component: CNavItem,
    },
  ],
])

const defaultAllowdPages = ['', '/', '/login', '/logout']

const generateSidebar = (sites) => {
  const pages = []

  for (const item of sites) {
    const page = navigationMap.get(item)
    if (page) {
      pages.push(page)
    }
  }
  pages.sort((first, second) => first.name.localeCompare(second.name))
  return pages
}

const getDefaultPage = (sites) => {
  const page = Array.isArray(sites) && sites.length > 0 ? sites[0] : null

  const selectedPage = page || defaultPage
  const navigation = navigationMap.get(selectedPage) || navigationMap.get(defaultPage)

  return navigation.to
}

const pathnameMatchBegin = (pathname, page) => {
  const pattern = new RegExp(`^${page}/`)
  return pattern.test(pathname)
}

const isPathnameAllowed = (pathname, sites) => {
  if (defaultAllowdPages.indexOf(pathname) !== -1) {
    return true
  }

  let pageName = null
  for (const [key, item] of navigationMap) {
    if ('_children' in item) {
      for (const children of item._children) {
        if (pathname === children.to || pathnameMatchBegin(pathname, children.to)) {
          pageName = key
          break
        }
      }
    } else if (pathname === item.to || pathnameMatchBegin(pathname, item.to)) {
      pageName = key
      break
    }
  }

  return pageName && !!sites.find((item) => item === pageName)
}

export { generateSidebar, getDefaultPage, isPathnameAllowed }

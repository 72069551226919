import Echo from 'laravel-echo'
import api from './services/api'
window.Pusher = require('pusher-js')

const websocketInit = () => {
  if (process.env.REACT_APP_WEBSOCKET_ENABLED === 'true') {
    try {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_APP_KEY,
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        wsHost: process.env.REACT_APP_PUSHER_APP_HOST,
        wsPort: process.env.REACT_APP_PUSHER_APP_PORT,
        wsPath: process.env.REACT_APP_PUSHER_APP_PATH,
        forceTLS: false,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel, options) => {
          return {
            authorize: (socketId, callback) => {
              api
                .post('/api/broadcasting/auth', {
                  socket_id: socketId,
                  channel_name: channel.name,
                })
                .then((response) => {
                  callback(null, response.data)
                })
                .catch((error) => {
                  callback(error)
                })
            },
          }
        },
      })
    } catch (error) {
      console.error('websocketInit', error)
    }
  }
}

const websocketDisconnect = () => {
  try {
    window.Echo.disconnect()
  } catch (error) {
    //
  }
}

export { websocketInit, websocketDisconnect }

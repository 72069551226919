/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Loading from './Loading'
import { USER_UPDATE, TOKEN_REMOVE, USER_REMOVE } from '../actions'
import api from '../services/api'
import { isPathnameAllowed } from '../navigation'

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.userStore.isAuthenticated)
  const token = useSelector((state) => state.tokenStore.token)
  const sites = useSelector((state) => state.userStore.sites)
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const dispatch = useDispatch()
  const [refresh, setRefresh] = useState(true)
  const [pathnameAllowed, setPathnameAllowed] = useState(false)

  const getUser = async () => {
    if (!token) {
      navigate('/logout')
      return
    }

    try {
      await api.get('/sanctum/csrf-cookie')
      const response = await api.get('/api/auth/user')
      dispatch({ type: USER_UPDATE, ...response.data })
    } catch (error) {
      dispatch({ type: TOKEN_REMOVE })
      dispatch({ type: USER_REMOVE })
    } finally {
      setRefresh(false)
    }
  }

  useEffect(() => {
    if (isPathnameAllowed(routerLocation.pathname, sites)) {
      setPathnameAllowed(true)
    } else {
      setPathnameAllowed(false)
    }
  }, [routerLocation.pathname])

  useEffect(() => {
    getUser()
  }, [])

  return refresh ? (
    <Loading />
  ) : isAuthenticated && pathnameAllowed ? (
    children
  ) : (
    <Navigate to="/login" />
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoute
